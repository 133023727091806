body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1rem;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fab{
  padding: 5px;
  text-decoration: none;
}

.MuiCardHeader-root {
  background-color: rgba(0,0,0,.03);
}

a {
  text-decoration: none;
  color: #007bff;
}

a:visited {
  text-decoration: none;
  color: #007bff !important;
}

a:hover {
  color: #004c9e;
}

a:active {
  color: #004c9e;
}

header{
  margin: 0; 
  z-index: 3;
  position: fixed !important;
}
